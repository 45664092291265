.userManagement-navigation {
  display: flex;
  align-items: center;
  padding: 20px 0 10px 10px;
  font-family: 'Muli', sans-serif;
}

.settings-text {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-family: 'Muli';
  font-size: 16px;
}